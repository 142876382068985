
import * as React from "react";
import { Redirect } from "react-router-dom";
import Router from "../../../../../pages/page-docs/src/Router.tsx";
import requestPilets from "../defaults/requestPilets.ts";
import plugins from "../defaults/plugins.ts";
import setup from "../defaults/setup.ts";

function replaceAllParams(path, params) {
  Object.keys(params).forEach(key => {
    path = path.split(':' + key).join(params[key]);
  });

  return path;
}

const NotFoundPage = React.lazy(() => import("../../../../../pages/page-docs/src/NotFound.tsx"));
const version = "1.16.0";
const updated = "Tue Dec 10 2024";
const publicUrl = "/";
const pages = {
  "/": ({ match }) => React.createElement(Redirect, { to: replaceAllParams("/general", match.params) })
};
const layouts = {
  "default": React.lazy(() => import("../defaults/Layout.tsx"))
};

export { Router, NotFoundPage, requestPilets, pages, plugins, setup, version, updated, publicUrl, layouts };
